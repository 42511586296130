// src/pages/InstructorsList.jsx
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getInstructors, API_BASE } from '../utils/api';
import { Link } from 'react-router-dom';
import './InstructorsList.css';

const InstructorsList = () => {
    const [instructors, setInstructors] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                const response = await getInstructors();
                if (response.status === 'success') {
                    setInstructors(response.instructors);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the instructors. Please try again.');
            }
        };
        fetchInstructors();
    }, []);

    if (error) {
        return <p className="error text-center">{error}</p>;
    }

    return (
        <>
            <Header />
            <Container className="instructors-list py-5">
                <h2 className="text-center mb-4">Instructors</h2>
                <Row>
                    {instructors.map((instructor) => (
                        <Col md="4" key={instructor.id}>
                            <Card className="mb-4">
                                <CardBody>
                                    <img src={API_BASE + instructor.image} alt={instructor.name} className="img-fluid mb-3" />
                                    <CardTitle tag="h5">{instructor.name}</CardTitle>
                                    <CardText>{instructor.expertise}</CardText>
                                    <Link to={`/instructor/${instructor.id}`}>
                                        <Button color="primary">View Profile</Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default InstructorsList;
