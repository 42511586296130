import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const ResetPasswordForm = ({ switchToLogin }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            setMessage('Reset link sent to your email');
            setEmail('');
        } else {
            setMessage('Please enter a valid email address');
        }
    };

    return (
        <div>
            <h2>Reset Password</h2>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="resetEmail">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="resetEmail"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </FormGroup>
                <Button color="primary" type="submit" block>Send Reset Link</Button>
            </Form>
            {message && <Alert className="mt-3" color={message === 'Reset link sent to your email' ? 'success' : 'danger'}>{message}</Alert>}
            <div className="text-center mt-3">
                <Button color="link" onClick={switchToLogin}>Back to Login</Button>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
