// src/pages/PurchasedCourse.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import withAuth from '../components/withAuth';
import Banner from '../components/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getCourse, API_BASE } from '../utils/api';
// import './PurchasedCourse.css';

const PurchasedCourse = ({ isAuthenticated }) => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await getCourse(id);
                console.log(response);
                if (response.status === 'success') {
                    setCourse(response.course);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the course. Please try again.');
            }
        };
        fetchCourse();
    }, [id]);

    if (error) {
        return <p className="error text-center">{error}</p>;
    }

    if (!course) {
        return <p className="text-center">Loading...</p>;
    }

    return (
        <>
            <section className="purchased-course py-5">
                <Container>
                    <Row>
                        <Col md="8">
                            <h1>{course.title}</h1>
                            <p><strong>Instructor:</strong> {course.instructor_name}</p>
                            <div className="course-video">
                                <img src={API_BASE + course.image_link} alt="Course video" />
                            </div>
                            <h2>Description</h2>
                            <p>{course.description}</p>
                            <h2>Resources</h2>
                            <ul className="resources-list">
                                {course.resources.map((resource, index) => (
                                    <li key={index}>
                                        {resource.title} - {resource.type} document
                                        <a href={API_BASE + resource.link} download>
                                            <i className="fa fa-download" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Course Details</CardTitle>
                                    <CardText><strong>Date of Course:</strong> {course.start_date}</CardText>
                                    <CardText><strong>Location:</strong> {course.location}</CardText>
                                    <CardText><strong>Number of Resources:</strong> {course.resources.length}</CardText>
                                    <CardText><strong>Duration of Course:</strong> {course.duration}</CardText>
                                    <CardText><strong>Date and Time:</strong> {`${course.start_date} - ${course.end_date}`}</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default withAuth(PurchasedCourse);
