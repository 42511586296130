// src/pages/Dashboard.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DashboardNav from '../components/DashboardNav';
import DashboardMain from '../components/DashboardMain';
import MyCourses from '../components/MyCourses';
import PurchasedCourse from '../components/PurchasedCourse';
import { Container, Row, Col } from 'reactstrap';
import './Dashboard.css';
import Profile from '../pages/Profile';
import MyTransactions from './MyTransactions';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Header />
            <Container fluid>
                <Row>
                    <Col md="3" className="sidebar">
                        <DashboardNav />
                    </Col>
                    <Col md="9" className="main-content">
                        <Routes>
                            <Route path="/" element={<DashboardMain />} />
                            <Route path="/courses" element={<MyCourses />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/purchased-course/:id" element={<PurchasedCourse />} />
                            <Route path='/my-transactions' element={<MyTransactions />} />
                            
                            {/* Add other routes here as needed */}
                        </Routes>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Dashboard;
