import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './Contact.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

const Contact = () => {
    return (
        <>
            <Header />
            <Banner pageName={'Contact Us'} />
            <section className="contact-page py-5">
                <Container>
                    {/* <h1>Contact</h1> */}
                    <Row className="mb-5">
                        {/* <Col md="4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Questions?</CardTitle>
                                    <CardText>Integer vitae faucibus erat. Maecenas ac interdum lorem, nec blandit lectus.</CardText>
                                    <Button color="primary">Visit FAQ</Button>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Media inquiries</CardTitle>
                                    <CardText>Integer vitae faucibus erat. Maecenas ac interdum lorem, nec blandit lectus.</CardText>
                                    <Button color="primary">Contact PR</Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Partnership inquiries</CardTitle>
                                    <CardText>Integer vitae faucibus erat. Maecenas ac interdum lorem, nec blandit lectus.</CardText>
                                    <Button color="primary">Contact Sales</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h2>Send us a message</h2>
                    <Row>
                        <Col md="6">
                            <Form>
                                <FormGroup>
                                    <Label for="name">Full Name</Label>
                                    <Input type="text" name="name" id="name" placeholder="Full Name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="email" name="email" id="email" placeholder="Email" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="phone">Phone number (optional)</Label>
                                    <Input type="text" name="phone" id="phone" placeholder="Phone number (optional)" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="message">Type your message here</Label>
                                    <Input type="textarea" name="message" id="message" placeholder="Type your message here" />
                                </FormGroup>
                                <FormGroup>
                                    <div className="g-recaptcha" data-sitekey="your_site_key"></div>
                                </FormGroup>
                                <Button color="primary" type="submit">SEND</Button>
                            </Form>
                        </Col>
                        <Col md="6">
                            <h3>Editorial Office</h3>
                            <p>
                                0231 East Parkway<br />
                                San Francisco, CA 93021<br />
                                Monday - Friday<br />
                                8AM-4PM CST<br />
                                1-312-123-3213
                            </p>
                            <h3>Shop Customer Service</h3>
                            <p>
                                90 Oak Tree Avenue<br />
                                Austin, TX 13021<br />
                                Monday - Friday<br />
                                8AM-4PM CST<br />
                                1-723-123-1223
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>

    );
};

export default Contact;
