import React, { useState, useEffect } from 'react';
import { Container, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { getAllCourses, deleteCourse } from '../utils/api';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AdminDashboardNav from '../components/AdminDashboardNav';
import AdminCourseForm from '../components/AdminCourseForm';
import './AdminCourses.css';

const AdminCourses = () => {
    const [courses, setCourses] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const toggleModal = () => setModal(!modal);

    useEffect(() => {
        const fetchCourses = async () => {
            const response = await getAllCourses();
            console.log(response)
            if (response.status === 'success') {
                setCourses(response.courses);
            }
        };
        fetchCourses();
    }, []);

    const handleEdit = (course) => {
        setSelectedCourse(course);
        toggleModal();
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this course?')) {
            try {
                const response = await deleteCourse(id);
                if (response.status === 'success') {
                    setCourses(courses.filter(course => course.id !== id));
                } else {
                    console.error('Failed to delete course:', response.message);
                }
            } catch (error) {
                console.error('Error deleting course:', error);
            }
        }
    };

    const handleAddNew = () => {
        setSelectedCourse(null);
        toggleModal();
    };

    const handleFormSubmit = (updatedCourse) => {
        if (selectedCourse) {
            setCourses(courses.map(course => course.id === updatedCourse.id ? updatedCourse : course));
        } else {
            setCourses([...courses, updatedCourse]);
        }
        toggleModal();
    };

    return (
        <>
            <Header />
            <Container fluid>
                <div className="admin-courses">
                    <AdminDashboardNav />
                    <div className="courses-content">
                        <Button color="primary" className="mb-3" onClick={handleAddNew}>Add New Course</Button>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Instructor</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.length > 0 ? courses.map(course => (
                                    <tr key={course.id}>
                                        <td>{course.id}</td>
                                        <td>{course.title}</td>
                                        <td>{course.instructor_id}</td>
                                        <td>{course.start_date}</td>
                                        <td>{course.end_date}</td>
                                        <td>
                                            <Button color="warning" size="sm" onClick={() => handleEdit(course)}>Edit</Button>{' '}
                                            <Button color="danger" size="sm" onClick={() => handleDelete(course.id)}>Delete</Button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No courses available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{selectedCourse ? 'Edit Course' : 'Add New Course'}</ModalHeader>
                    <ModalBody>
                        <AdminCourseForm course={selectedCourse} onSubmit={handleFormSubmit} />
                    </ModalBody>
                </Modal>
            </Container>
            <Footer />
        </>
        
    );
};

export default AdminCourses;
