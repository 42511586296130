// src/pages/SearchResults.jsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { API_BASE, searchCourses } from '../utils/api';
import './SearchResults.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Banner from '../components/Banner';

const SearchResults = () => {
    const location = useLocation();
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await searchCourses(location.state);
                console.log(response)
                if (response.status === 'success') {
                    setCourses(response.courses);
                } else {
                    console.error('Failed to fetch search results:', response.message);
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        fetchSearchResults();
    }, [location.state]);

    return (
        <>
            <Header />
            <Banner pageName='Search Result' />
            <Container className="search-results">
                <h2 className="text-center my-4">Search Results</h2>
                <Row>
                    {courses.length > 0 ? courses.map(course => (
                        <Col md="4" key={course.id} className="mb-4">
                            <Card>
                                <Link to={`/course/${course.id}`}>
                                    <CardImg top width="100%" src={API_BASE + course.image_link} alt={course.title} className='course-image' />
                                    <CardBody className="text-center">
                                        <CardTitle tag="h5">{course.title}</CardTitle>
                                        <CardText tag="h4">{course.instructor_name} • {new Date(course.start_date).toLocaleDateString()}</CardText>
                                        <CardText>{course.description}</CardText>
                                    </CardBody>
                                </Link>
                            </Card>
                        </Col>
                    )) : (
                        <Col>
                            <p className="text-center">No courses found.</p>
                        </Col>
                    )}
                </Row>
            </Container>
            <Footer />
        </>
        
    );
};

export default SearchResults;
