import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Login from '../components/Login';
import Register from '../components/Register';
import ResetPasswordForm from '../components/ResetPasswordForm';
import './MyAccount.css';
import HelmetWrapper from '../components/HelmetWrapper';

const MyAccount = () => {
    const [currentForm, setCurrentForm] = useState('login');

    const switchToRegister = () => setCurrentForm('register');
    const switchToLogin = () => setCurrentForm('login');
    const switchToResetPassword = () => setCurrentForm('resetPassword');

    return (
        <>
            <HelmetWrapper title="My Account" description="Manage your account details on Schedular" />
            <section className="my-account-page">
                <Container fluid className="my-account-container">
                    <Row noGutters>
                        <Col md="6" className="form-section">
                            {currentForm === 'login' && <Login switchToRegister={switchToRegister} switchToResetPassword={switchToResetPassword} />}
                            {currentForm === 'register' && <Register switchToLogin={switchToLogin} />}
                            {currentForm === 'resetPassword' && <ResetPasswordForm switchToLogin={switchToLogin} />}
                        </Col>
                        <Col md="6" className="image-section">
                            <div className="image-content">
                                <h2>Technology is the present and future!!</h2>
                                <p>With easy access to high level tech in aero space, you can change the future with your mind.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
        
    );
};

export default MyAccount;
