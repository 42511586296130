import React from 'react';
import AuthModal from './AuthModal';

const withAuth = (WrappedComponent) => {
    return class extends React.Component {
        state = {
            isAuthenticated: !!localStorage.getItem('token'), // Check if the user is authenticated
            isModalOpen: false,
            courseId: null,
            amount: null,
        };

        toggleModal = (courseId, amount) => {
            this.setState((prevState) => ({
                isModalOpen: !prevState.isModalOpen,
                courseId: courseId || prevState.courseId,
                amount: amount || prevState.amount,
            }));
        };

        render() {
            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        isAuthenticated={this.state.isAuthenticated}
                        toggleModal={this.toggleModal}
                    />
                    <AuthModal
                        isOpen={this.state.isModalOpen}
                        toggle={this.toggleModal}
                        isAuthenticated={this.state.isAuthenticated}
                        courseId={this.state.courseId}
                        amount={this.state.amount}
                    />
                </>
            );
        }
    };
};

export default withAuth;
