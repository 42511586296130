import React from 'react';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import UpcomingCourses from '../components/UpcomingCourses';
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';
import HelmetWrapper from '../components/HelmetWrapper';

const Home = () => {
    return (
        <>
            <HelmetWrapper title={'Home'} description={'Schedular is a security first Course'} />
            <Header />
            <SearchBar />
            <UpcomingCourses />
            <Newsletter />
            <Footer />
        </>
    );
};

export default Home;