// src/components/SearchBar.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import './SearchBar.css';

const SearchBar = () => {
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [lectureType, setLectureType] = useState('');

    const navigate = useNavigate();

    const handleSearch = () => {
        const searchParams = {};
        if (date) searchParams.date = date;
        if (location) searchParams.location = location;
        if (lectureType) searchParams.lectureType = lectureType;

        navigate('/search-results', {
            state: searchParams,
        });
    };

    return (
        <section className="search-bar bg-dark text-white py-5">
            <Container className='my-5'>
                <h2 className="text-center mb-4">Search for the Courses Happening <br/>in your Environ</h2>
                <Row>
                    <Col md="4" className='my-2'>
                        <Input 
                            type="date" 
                            placeholder="Select Date" 
                            value={date} 
                            onChange={(e) => setDate(e.target.value)} 
                        />
                    </Col>
                    <Col md="4" className='my-2'>
                        <Input 
                            type="text" 
                            placeholder="Select Location" 
                            value={location} 
                            onChange={(e) => setLocation(e.target.value)} 
                        />
                    </Col>
                    <Col md="4"  className='my-2'>
                        <FormGroup>
                            {/* <Label for="lectureType">Lecture Type</Label> */}
                            <Input 
                                type="select" 
                                name="lectureType" 
                                id="lectureType" 
                                value={lectureType} 
                                onChange={(e) => setLectureType(e.target.value)} 
                            >
                                <option value="">Select Lecture Type</option>
                                <option value="online">Online</option>
                                <option value="physical">Physical</option>
                                <option value="both">Both</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="text-center mt-4">
                    <Button color="primary" onClick={handleSearch}>Search</Button>
                </div>
            </Container>
        </section>
    );
};

export default SearchBar;
