import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Header from '../components/Header';
import Courses from '../components/Courses';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

const AllCourses = () => {
    return (
        <>
            <Header />
            <Banner pageName='Courses' />
            <Courses />
            <Footer />
        </>

    );
};

export default AllCourses;
