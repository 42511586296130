import React, { useState } from 'react';
import { Container, Row, Col, Button, Input, FormGroup, Form, Alert } from 'reactstrap';
import './Newsletter.css';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            setMessage('Contact saved successfully');
            setEmail('');
        } else {
            setMessage('Please enter a valid email address');
        }
    };

    return (
        <section className="newsletter py-5 my-5">
            <Container className="newsletter-container">
                <h2 className="text-center mb-4">Subscribe to our Newsletter</h2>
                <p className="text-center">Get the latest product updates, company news, and special offers delivered right to your inbox.</p>
                <Form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <FormGroup className="d-flex">
                                <Input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="mr-2"
                                />
                                <Button color="primary" type="submit">Subscribe</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    {message && (
                        <Row className="justify-content-center mt-3">
                            <Col md="8">
                                <Alert color={message === 'Contact saved successfully' ? 'success' : 'danger'}>
                                    {message}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </Form>
            </Container>
        </section>
    );
};

export default Newsletter;
