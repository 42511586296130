import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MyAccount from './pages/MyAccount';
import SingleCourse from './pages/SingleCourse';
import Contact from './pages/Contact';
import Dashboard from './components/Dashboard';
import './App.css';
import AllCourses from './pages/AllCourses';
import InstructorsList from './pages/InstructorsList';
import InstructorProfile from './pages/InstructorProfile';
import AdminDashboard from './components/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AdminCourses from './components/AdminCourses';
import SearchResults from './pages/SearchResults';
import AdminUsers from './components/AdminUsers';
import AdminInstructors from './components/AdminInstructors';
import AdminTransactions from './components/AdminTransactions';
import ProfileEdit from './components/ProfileEdit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<AllCourses />} />
        <Route path="/course/:id" element={<SingleCourse />} />
        {/* <Route path="/instructors" element={<Instructors />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/instructors" element={<InstructorsList />} />
        <Route path="/instructor/:id" element={<InstructorProfile />} />
        <Route path="/account" element={<ProtectedRoute element={MyAccount} path="/account" />} />
        {/* <Route path="/account" element={<MyAccount />} /> */}
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/admin/*" element={<AdminDashboard />} />
        <Route path="/admin/courses" element={<AdminCourses />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/instructors" element={<AdminInstructors />} />
        <Route path="/admin/transactions" element={<AdminTransactions />} />
        <Route path="/admin/profile" element={<ProfileEdit />} />
        <Route path="/search-results" element={<SearchResults />} />
      </Routes>
    </Router>
  );
}

export default App;