// src/pages/SingleCourse.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import withAuth from '../components/withAuth';
import Banner from '../components/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UpcomingCourses from '../components/UpcomingCourses';
import { getCourse, API_BASE } from '../utils/api';
import './SingleCourse.css';
import HelmetWrapper from '../components/HelmetWrapper';

const SingleCourse = ({ isAuthenticated, toggleModal }) => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await getCourse(id);
                console.log(response);
                if (response.status === 'success') {
                    setCourse(response.course);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the course. Please try again.');
            }
        };
        fetchCourse();
    }, [id]);

    if (error) {
        return <p className="error text-center">{error}</p>;
    }

    if (!course) {
        return <p className="text-center">Loading...</p>;
    }

    const handleResourceDownload = (link) => {
        if (isAuthenticated) {
            window.location.href = API_BASE + link;
        } else {
            toast.error('Unable to download until payment is made');
        }
    };

    return (
        <>
            <HelmetWrapper title={course.title} description={course.description} />
            <Header />
            <Banner pageName={course.title} />
            <section className="single-course py-5">
                <Container>
                    <Row>
                        <Col md="8">
                            <h1>{course.title}</h1>
                            <p><strong>Instructor:</strong> {course.instructor_name}</p>
                            <div className="course-video">
                                <img src={API_BASE+course.image_link} alt="Course video" />
                            </div>
                            <h2>Description</h2>
                            <p>{course.description}</p>
                            <h2>Resources</h2>
                            <ul className="resources-list">
                                {course.resources.map((resource, index) => (
                                    <li key={index}>
                                        {resource.title} - {resource.type} document
                                        <a href="#" onClick={() => handleResourceDownload(resource.link)}>
                                            <i className="fa fa-download" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="share">
                                <h2>Share</h2>
                                <a href={`https://twitter.com/share?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a href={`mailto:?subject=Check out this course&body=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-envelope"></i>
                                </a>
                            </div>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Course Details</CardTitle>
                                    <CardText><strong>Date of Course:</strong> {course.start_date}</CardText>
                                    {/* <CardText><strong>Instructor:</strong> {course.instructor.name}</CardText> */}
                                    <CardText><strong>Location:</strong> {course.location}</CardText>
                                    <CardText><strong>Number of Resources:</strong> {course.resources.length}</CardText>
                                    <CardText><strong>Duration of Course:</strong> {3}</CardText>
                                    <CardText><strong>Date and Time:</strong> {course.start_date}</CardText>
                                    <CardText><strong>Price:</strong> ₦{course.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</CardText>
                                    <Button color="primary" className="mt-3" block onClick={() => toggleModal(course.id, course.price)}>
                                        {isAuthenticated ? 'Make Payment' : 'Login / Register'}
                                    </Button>
                                </CardBody>
                            </Card>
                            {/* <div className="advertisement">
                                <img src="/path_to_ad_image.jpg" alt="Advertisement" />
                            </div> */}
                        </Col>
                    </Row>
                    {/* <h2>Other Upcoming Courses</h2> */}
                    <UpcomingCourses />
                    {/* <Row>
                        {course.otherCourses.map((otherCourse, index) => (
                            <Col md="4" key={index}>
                                <Card>
                                    <CardBody>
                                        <CardTitle tag="h5">{otherCourse.title}</CardTitle>
                                        <CardText>{otherCourse.description}</CardText>
                                        <Button color="primary">View Course</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row> */}
                </Container>
            </section>
            <Footer />
        </>
    );
};

export default withAuth(SingleCourse);
