// src/components/AdminDashboardNav.jsx
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FaTachometerAlt, FaBook, FaDollarSign, FaUser, FaUsers, FaChalkboardTeacher } from 'react-icons/fa';
import './AdminDashboardNav.css';

const AdminDashboardNav = () => {
    return (
        <div className="admin-dashboard-nav">
            <Nav vertical>
                <NavItem>
                    <NavLink href="/admin/dashboard">
                        <FaTachometerAlt className="nav-icon" /> Dashboard
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/admin/courses">
                        <FaBook className="nav-icon" /> Courses
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/admin/users">
                        <FaUsers className="nav-icon" /> Users
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/admin/transactions">
                        <FaDollarSign className="nav-icon" /> Transactions
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/admin/instructors">
                        <FaChalkboardTeacher className="nav-icon" /> Instructors
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/admin/profile">
                        <FaUser className="nav-icon" /> Profile
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    );
};

export default AdminDashboardNav;
