// src/components/DashboardMain.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, CardText } from 'reactstrap';
import './DashboardMain.css';

const DashboardMain = () => {
    const [doneCourses, setDoneCourses] = useState(0);
    const [upcomingCourses, setUpcomingCourses] = useState(0);

    useEffect(() => {

    }, []);

    return (
        <div className="dashboard-main">
            <h2 className="dashboard-title">Dashboard</h2>
            <div className="dashboard-stats">
                <Card className="stat-card">
                    <CardBody>
                        <div className="stat-icon">
                            <i className="icon">9</i>
                        </div>
                        <CardText className="stat-text">
                            <span>{doneCourses}</span>
                            <p>Courses Completed</p>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-card">
                    <CardBody>
                        <div className="stat-icon">
                            <i className="icon">11</i>
                        </div>
                        <CardText className="stat-text">
                            <span>{upcomingCourses}</span>
                            <p>Upcoming Courses</p>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default DashboardMain;
