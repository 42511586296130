// src/components/AllCourses.jsx
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { getCourses, API_BASE } from '../utils/api';
import { Link } from 'react-router-dom';
import './AllCourses.css';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await getCourses();
                if (response.status === 'success') {
                    setCourses(response.courses);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching courses. Please try again.');
            }
        };
        fetchCourses();
    }, []);

    return (
        <section className="all-courses py-5">
            <Container>
                <h2 className="text-center mb-4">All Courses</h2>
                {error && <p className="error text-center">{error}</p>}
                <Row>
                    {courses.map(course => (
                        <Col md="4" key={course.id}>
                            <Card className="mb-4">
                                <Link to={`/course/${course.id}`}>
                                    <CardImg top width="100%" src={API_BASE + course.image_link} alt="Card image cap" className='course-image' />
                                    <CardBody className="text-center">
                                        <CardTitle tag="h5">{course.title}</CardTitle>
                                        <CardText tag="h4">{course.instructor_name} • {new Date(course.start_date).toLocaleDateString()}</CardText>
                                        <CardText>{course.description}</CardText>
                                    </CardBody>
                                </Link>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Courses;
