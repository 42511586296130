// src/components/AdminDashboardMain.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, CardText } from 'reactstrap';
// import './AdminDashboardMain.css';

const AdminDashboardMain = () => {
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalCourses, setTotalCourses] = useState(0);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const userResponse = await axios.get('/api/admin/total-users');
                const courseResponse = await axios.get('/api/admin/total-courses');
                setTotalUsers(userResponse.data.total);
                setTotalCourses(courseResponse.data.total);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };
        fetchStats();
    }, []);

    return (
        <div className="admin-dashboard-main">
            <h2 className="dashboard-title">Admin Dashboard</h2>
            <div className="dashboard-stats">
                <Card className="stat-card">
                    <CardBody>
                        <div className="stat-icon">
                            <i className="icon">U</i>
                        </div>
                        <CardText className="stat-text">
                            <span>{totalUsers}</span>
                            <p>Total Users</p>
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="stat-card">
                    <CardBody>
                        <div className="stat-icon">
                            <i className="icon">C</i>
                        </div>
                        <CardText className="stat-text">
                            <span>{totalCourses}</span>
                            <p>Total Courses</p>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default AdminDashboardMain;
