// src/pages/AdminInstructors.jsx
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  getInstructors,
  createInstructor,
  editInstructor,
  getInstructor,
  deleteInstructor,
} from "../utils/api";
import InstructorForm from "../components/InstructorForm";
import AdminDashboardNav from "../components/AdminDashboardNav";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminInstructors.css";
import Footer from "./Footer";
import Header from "./Header";

const AdminInstructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);

  useEffect(() => {
    const fetchInstructors = async () => {
      const response = await getInstructors();
      if (response.status === "success") {
        setInstructors(response.instructors);
      }
    };
    fetchInstructors();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleCreateInstructor = async (instructorData) => {
    const response = await createInstructor(instructorData);
    if (response.status === "success") {
      setInstructors([...instructors, response.instructor]);
      toast.success("Instructor created successfully");
      toggleModal();
    } else {
      toast.error("Failed to create instructor");
    }
  };

  const handleEditInstructor = async (instructorData) => {
    const response = await editInstructor(instructorData);
    if (response.status === "success") {
      const updatedInstructors = instructors.map((instructor) =>
        instructor.id === instructorData.id
          ? { ...instructor, ...instructorData }
          : instructor
      );
      setInstructors(updatedInstructors);
      toast.success("Instructor edited successfully");
      toggleModal();
    } else {
      toast.error("Failed to edit instructor");
    }
  };

  const handleDeleteInstructor = async (id) => {
    if (window.confirm("Are you sure you want to delete this instructor?")) {
      const response = await deleteInstructor(id);
      if (response.status === "success") {
        setInstructors(
          instructors.filter((instructor) => instructor.id !== id)
        );
        toast.success("Instructor deleted successfully");
      } else {
        toast.error("Failed to delete instructor");
      }
    }
  };

  const openEditModal = async (id) => {
    const response = await getInstructor(id);
    if (response.status === "success") {
      setSelectedInstructor(response.instructor);
      setIsEditing(true);
      toggleModal();
    }
  };

  return (
    <>
      <Header />
      <div className="admin-layout">
        <AdminDashboardNav />
        <div className="admin-content">
          <Container fluid>
            <Row className="mb-4">
              <Col>
                <h1 className="text-center">All Instructors</h1>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedInstructor(null);
                    toggleModal();
                  }}
                >
                  Create New Instructor
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      {/* <th>Email</th> */}
                      <th>Expertise</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {instructors.map((instructor, index) => (
                      <tr key={instructor.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{instructor.name}</td>
                        {/* <td>{instructor.email}</td> */}
                        <td>{instructor.expertise}</td>
                        <td>
                          <Button
                            color="warning"
                            onClick={() => openEditModal(instructor.id)}
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() =>
                              handleDeleteInstructor(instructor.id)
                            }
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>
                {isEditing ? "Edit Instructor" : "Create New Instructor"}
              </ModalHeader>
              <ModalBody>
                <InstructorForm
                  onSubmit={
                    isEditing ? handleEditInstructor : handleCreateInstructor
                  }
                  instructor={isEditing ? selectedInstructor : null}
                />
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminInstructors;
