// src/pages/MyTransactions.jsx
import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getMyTransactions } from '../utils/api';
import DataTable from 'react-data-table-component';
import './MyTransactions.css';

const MyTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await getMyTransactions();
                if (response.status === 'success') {
                    setTransactions(response.transactions);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the transactions. Please try again.');
            }
        };
        fetchTransactions();
    }, []);

    const columns = [
        {
            name: 'Transaction ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Course Name',
            selector: row => row.course_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true,
        }
    ];

    return (
        <>
            <Container className="my-transactions py-5">
                <h2 className="text-center mb-4">My Transactions</h2>
                {error && <p className="error text-center">{error}</p>}
                <DataTable
                    columns={columns}
                    data={transactions}
                    pagination
                />
            </Container>
        </>
    );
};

export default MyTransactions;
