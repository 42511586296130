// src/components/MyCourses.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getMyCourses, API_BASE } from '../utils/api';
import { Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import './MyCourses.css';

const MyCourses = () => {
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await getMyCourses();
                if (response.status === 'success') {
                    setCourses(response.courses);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching courses. Please try again.');
            }
        };
        fetchCourses();
    }, []);

    return (
        <div className="my-courses-container">
            <h2>My Courses</h2>
            {error && <p className="error">{error}</p>}
            <div className="courses-list">
                {courses.map(course => (
                    <Link to={`/dashboard/purchased-course/${course.id}`}>
                        <Card key={course.id} className="course-card">
                            <CardImg top width="100%" src={API_BASE + course.image_link} alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">{course.title}</CardTitle>
                                <CardText>{course.description}</CardText>
                                <CardText>
                                    <small className="text-muted">Start Date: {course.start_date}</small>
                                </CardText>
                                <CardText>
                                    <small className="text-muted">End Date: {course.end_date}</small>
                                </CardText>
                                <CardText>
                                    <small className="text-muted">Price: ₦{course.price}</small>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default MyCourses;
