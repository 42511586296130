// src/pages/AdminUsers.jsx
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  getAllUsers,
  createUser,
  editUser,
  getSingleUser,
  deleteUser,
} from "../utils/api";
import UserForm from "../components/UserForm";
import AdminDashboardNav from "../components/AdminDashboardNav";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminUsers.css";
import Header from "./Header";
import Footer from "./Footer";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAllUsers();
      if (response.status === "success") {
        setUsers(response.users);
      }
    };
    fetchUsers();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleCreateUser = async (userData) => {
    const response = await createUser(userData);
    if (response.status === "success") {
      setUsers([...users, response.user]);
      toast.success("User created successfully");
      toggleModal();
    } else {
      toast.error("Failed to create user");
    }
  };

  const handleEditUser = async (userData) => {
    const response = await editUser(userData);
    if (response.status === "success") {
      const updatedUsers = users.map((user) =>
        user.id === userData.id ? { ...user, ...userData } : user
      );
      setUsers(updatedUsers);
      toast.success("User edited successfully");
      toggleModal();
    } else {
      toast.error("Failed to edit user");
    }
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const response = await deleteUser(id);
      if (response.status === "success") {
        setUsers(users.filter((user) => user.id !== id));
        toast.success("User deleted successfully");
      } else {
        toast.error("Failed to delete user");
      }
    }
  };

  const openEditModal = async (id) => {
    const response = await getSingleUser(id);
    if (response.status === "success") {
      setSelectedUser(response.user);
      setIsEditing(true);
      toggleModal();
    }
  };

  return (
    <>
      <Header />
      <div className="admin-layout">
        <AdminDashboardNav />
        <div className="admin-content">
          <Container fluid>
            <Row className="mb-4">
              <Col>
                <h1 className="text-center">All Users</h1>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedUser(null);
                    toggleModal();
                  }}
                >
                  Create New User
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.type}</td>
                        <td>
                          <Button
                            color="warning"
                            onClick={() => openEditModal(user.id)}
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() => handleDeleteUser(user.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>
                {isEditing ? "Edit User" : "Create New User"}
              </ModalHeader>
              <ModalBody>
                <UserForm
                  onSubmit={isEditing ? handleEditUser : handleCreateUser}
                  user={isEditing ? selectedUser : null}
                />
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminUsers;
