// src/components/InstructorForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';
import { uploadImage } from '../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InstructorForm = ({ onSubmit, instructor }) => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        expertise: '',
        bio: '',
        image: ''
    });

    const dropifyRef = useRef();

    useEffect(() => {
        if (instructor) {
            setFormData({
                id: instructor.id,
                name: instructor.name,
                email: instructor.email,
                expertise: instructor.expertise,
                bio: instructor.bio,
                image: instructor.image
            });
        }

        const $dropify = $(dropifyRef.current).dropify({
            messages: {
                'default': 'Drag and drop a file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove': 'Remove',
                'error': 'Ooops, something wrong happened.'
            },
            error: {
                'fileSize': 'The file size is too big ({{ value }} max).'
            }
        });

        $dropify.on('change', handleImageUpload);

        return () => {
            $dropify.data('dropify').destroy();
        };
    }, [instructor]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const data = await uploadImage(file);
                if (data.status === 'success') {
                    setFormData(prevState => ({ ...prevState, image: data.file_link }));
                    toast.success('Image uploaded successfully');
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                toast.error('Image upload failed');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="name">Name</Label>
                <Input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            {/* <FormGroup>
                <Label for="email">Email</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </FormGroup> */}
            <FormGroup>
                <Label for="expertise">Expertise</Label>
                <Input
                    type="text"
                    name="expertise"
                    id="expertise"
                    value={formData.expertise}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="bio">Bio</Label>
                <Input
                    type="textarea"
                    name="bio"
                    id="bio"
                    value={formData.bio}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="image">Picture Link</Label>
                <Input
                    type="file"
                    className="dropify"
                    data-default-file={formData.image}
                    data-allowed-file-extensions="jpg jpeg png gif"
                    innerRef={dropifyRef}
                />
                <Input
                    type="text"
                    name="image"
                    id="image"
                    value={formData.image}
                    onChange={handleChange}
                    readOnly
                    hidden
                />
            </FormGroup>
            <Button color="primary" type="submit" block>
                Submit
            </Button>
        </Form>
    );
};

export default InstructorForm;
