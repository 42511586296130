// // src/pages/AdminDashboard.jsx
// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import Banner from '../components/Banner';
// import { getTotalUsers, getTotalCourses } from '../utils/api';
// import './AdminDashboard.css';

// const AdminDashboard = () => {
//     const [totalUsers, setTotalUsers] = useState(0);
//     const [totalCourses, setTotalCourses] = useState(0);
//     const [error, setError] = useState('');

//     useEffect(() => {
//         const fetchStats = async () => {
//             try {
//                 const usersResponse = await getTotalUsers();
//                 const coursesResponse = await getTotalCourses();

//                 if (usersResponse.status === 'success') {
//                     setTotalUsers(usersResponse.totalUsers);
//                 } else {
//                     setError(usersResponse.message);
//                 }

//                 if (coursesResponse.status === 'success') {
//                     setTotalCourses(coursesResponse.totalCourses);
//                 } else {
//                     setError(coursesResponse.message);
//                 }
//             } catch (err) {
//                 setError('An error occurred while fetching stats. Please try again.');
//             }
//         };
//         fetchStats();
//     }, []);

//     return (
//         <>
//             <Container className="admin-dashboard py-5">
//                 <Row>
//                     <Col md="6">
//                         <Card className="mb-4">
//                             <CardBody>
//                                 <CardTitle tag="h5">Total Users</CardTitle>
//                                 <h3>{totalUsers}</h3>
//                             </CardBody>
//                         </Card>
//                     </Col>
//                     <Col md="6">
//                         <Card className="mb-4">
//                             <CardBody>
//                                 <CardTitle tag="h5">Total Courses</CardTitle>
//                                 <h3>{totalCourses}</h3>
//                             </CardBody>
//                         </Card>
//                     </Col>
//                 </Row>
//                 {error && <p className="error text-center">{error}</p>}
//             </Container>
//         </>
//     );
// };

// export default AdminDashboard;

// src/pages/AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import AdminDashboardNav from '../components/AdminDashboardNav';
import AdminDashboardMain from '../components/AdminDashboardMain';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './AdminDashboard.css';

const AdminDashboard = () => {
    return (
        <>
            <Header />
            <Container fluid>
                <Row>
                    <Col md="3">
                        <AdminDashboardNav />
                    </Col>
                    <Col md="9">
                        <AdminDashboardMain />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default AdminDashboard;

