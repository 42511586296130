import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Login from './Login';
import Register from './Register';
import { getUserData, registerCourse } from '../utils/api';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const AuthModal = ({ isOpen, toggle, isAuthenticated, courseId, amount }) => {
    const [isLogin, setIsLogin] = useState(true);
    const user = getUserData();

    const config = {
        public_key: 'FLWPUBK_TEST-92d7d58caf0f03f5957fcbda7979eb66-X',
        tx_ref: Date.now(),
        amount: amount,
        currency: 'NGN',
        payment_options: 'card, banktransfer, ussd',
        customer: {
            email: isAuthenticated ? user.email : 'info@comnavig.com',
            phonenumber: '070********',
            name: isAuthenticated ? user.name : 'Schedular',
        },
        customizations: {
            title: 'Schedular',
            description: 'Payment for items in cart',
            logo: 'https://www.comnavig.com/images/logo.png',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const handlePayment = () => {
        handleFlutterPayment({
            callback: async (response) => {
                console.log(response);
                if (response.status === 'successful') {
                    await registerCourse(courseId, amount);
                    //TODO - Navigation to dashboard
                }
                closePaymentModal(); // this will close the modal programmatically
            },
            onClose: () => { },
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {isAuthenticated ? 'Make Payment' : isLogin ? 'Login' : 'Register'}
            </ModalHeader>
            <ModalBody>
                {isAuthenticated ? (
                    <Button color="primary" onClick={handlePayment}>
                        Pay Now
                    </Button>
                ) : isLogin ? (
                    <Login switchToRegister={() => setIsLogin(false)} />
                ) : (
                    <Register switchToLogin={() => setIsLogin(true)} />
                )}
            </ModalBody>
        </Modal>
    );
};

export default AuthModal;
