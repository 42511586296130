// src/components/UserForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';
import { uploadImage } from '../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserForm = ({ onSubmit, user }) => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        password: '',
        type: 'student',
        picture_link: ''
    });

    const dropifyRef = useRef();

    useEffect(() => {
        if (user) {
            setFormData({
                id: user.id,
                name: user.name,
                email: user.email,
                password: '',
                type: user.type,
                picture_link: user.picture_link
            });
        }

        const $dropify = $(dropifyRef.current).dropify({
            messages: {
                'default': 'Drag and drop a file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove': 'Remove',
                'error': 'Ooops, something wrong happened.'
            },
            error: {
                'fileSize': 'The file size is too big ({{ value }} max).'
            }
        });

        $dropify.on('change', handleImageUpload);

        return () => {
            $dropify.data('dropify').destroy();
        };
    }, [user]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const data = await uploadImage(file);
                if (data.status === 'success') {
                    setFormData(prevState => ({ ...prevState, picture_link: data.file_link }));
                    toast.success('Image uploaded successfully');
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                toast.error('Image upload failed');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="name">Name</Label>
                <Input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="email">Email</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </FormGroup>
            {!user && (
                <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </FormGroup>
            )}
            <FormGroup>
                <Label for="type">type</Label>
                <Input
                    type="select"
                    name="type"
                    id="type"
                    value={formData.type}
                    onChange={handleChange}
                    required
                >
                    <option value="student">Student</option>
                    <option value="admin">Admin</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="picture_link">Picture Link</Label>
                <Input
                    type="file"
                    className="dropify"
                    data-default-file={formData.picture_link}
                    data-allowed-file-extensions="jpg jpeg png gif"
                    innerRef={dropifyRef}
                />
                <Input
                    type="text"
                    name="picture_link"
                    id="picture_link"
                    value={formData.picture_link}
                    onChange={handleChange}
                    readOnly
                    hidden
                />
            </FormGroup>
            <Button color="primary" type="submit" block>
                Submit
            </Button>
        </Form>
    );
};

export default UserForm;
