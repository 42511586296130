import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import axios from 'axios';
import { uploadImage, createCourse } from '../utils/api';
import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';

const AdminCourseForm = ({ course, onSubmit }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image_link: '',
        price: '',
        start_date: '',
        end_date: '',
        location: '',
        lecture_type: 'online',
        platform: '',
        resources: [{ title: '', type: '', link: '' }]
    });

    useEffect(() => {
        if (course) {
            setFormData({
                title: course.title,
                description: course.description,
                image_link: course.image_link,
                price: course.price,
                start_date: course.start_date,
                end_date: course.end_date,
                location: course.location,
                lecture_type: course.lecture_type,
                platform: course.platform,
                resources: course.resources || [{ title: '', type: '', link: '' }]
            });
        }
    }, [course]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const data = await uploadImage(file);
                if (data.status === 'success') {
                    setFormData(prevState => ({ ...prevState, image_link: data.file_link }));
                }
            } catch (error) {
                console.error('Image upload failed:', error);
            }
        }
    };

    useEffect(() => {
        const dropifyElements = $('.dropify').dropify({
            messages: {
                'default': 'Drag and drop a file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove': 'Remove',
                'error': 'Ooops, something wrong happened.'
            },
            error: {
                'fileSize': 'The file size is too big ({{ value }} max).'
            }
        });

        dropifyElements.on('change', handleImageUpload);

        return () => {
            dropifyElements.data('dropify').destroy();
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleResourceChange = (index, e) => {
        const { name, value } = e.target;
        const newResources = formData.resources.map((resource, i) => {
            if (i === index) {
                return { ...resource, [name]: value };
            }
            return resource;
        });
        setFormData({ ...formData, resources: newResources });
    };

    const addResource = () => {
        setFormData({ ...formData, resources: [...formData.resources, { title: '', type: '', link: '' }] });
    };

    const removeResource = (index) => {
        setFormData({ ...formData, resources: formData.resources.filter((_, i) => i !== index) });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (course && course.id) {
            const response = await axios.post('/index.php?action=update_course', { ...formData, id: course.id });
            if (response.status === 'success') {
                onSubmit(response.course);
            }
        } else {
            const response = await createCourse(formData);
            if (response.status === 'success') {
                onSubmit(response.course);

            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="title">Title</Label>
                <Input type="text" name="title" id="title" value={formData.title} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <Input type="textarea" name="description" id="description" value={formData.description} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="image_link">Image</Label>
                <Input type="file" className="dropify" data-default-file={formData.image_link} />
            </FormGroup>
            <FormGroup>
                <Label for="image_link_display">Image Link</Label>
                <Input type="text" name="image_link_display" id="image_link_display" value={formData.image_link} disabled />
            </FormGroup>
            <FormGroup>
                <Label for="price">Price</Label>
                <Input type="number" name="price" id="price" value={formData.price} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="start_date">Start Date</Label>
                <Input type="date" name="start_date" id="start_date" value={formData.start_date} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="end_date">End Date</Label>
                <Input type="date" name="end_date" id="end_date" value={formData.end_date} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="location">Location</Label>
                <Input type="text" name="location" id="location" value={formData.location} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label for="lecture_type">Lecture Type</Label>
                <Input type="select" name="lecture_type" id="lecture_type" value={formData.lecture_type} onChange={handleChange} required>
                    <option value="online">Online</option>
                    <option value="physical">Physical</option>
                    <option value="both">Both</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="platform">Platform</Label>
                <Input type="text" name="platform" id="platform" value={formData.platform} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
                <Label>Resources</Label>
                {formData.resources.map((resource, index) => (
                    <Row key={index} className="mb-2">
                        <Col md={4}>
                            <Input
                                type="text"
                                name="title"
                                placeholder="Resource Title"
                                value={resource.title}
                                onChange={e => handleResourceChange(index, e)}
                                required
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="type"
                                placeholder="Resource Type"
                                value={resource.type}
                                onChange={e => handleResourceChange(index, e)}
                                required
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="link"
                                placeholder="Resource Link"
                                value={resource.link}
                                onChange={e => handleResourceChange(index, e)}
                                required
                            />
                        </Col>
                        <Col md={12} className="text-right mt-2">
                            <Button color="danger" size="sm" onClick={() => removeResource(index)}>Remove</Button>
                        </Col>
                    </Row>
                ))}
                <Button color="secondary" size="sm" onClick={addResource}>Add Resource</Button>
            </FormGroup>
            <Button color="primary" type="submit" block>Submit</Button>
        </Form>
    );
};

export default AdminCourseForm;
