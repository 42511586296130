// src/components/Banner.jsx
import React from 'react';
import './Banner.css';

const Banner = ({ pageName }) => {
    return (
        <div className="banner">
            <h1 className="banner-title">{pageName}</h1>
        </div>
    );
};

export default Banner;
