// src/components/DashboardNav.jsx
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FaTachometerAlt, FaBook, FaDollarSign, FaUser } from 'react-icons/fa';
import './DashboardNav.css';

const DashboardNav = () => {
    return (
        <Nav vertical className="dashboard-nav">
            <NavItem>
                <NavLink href="/dashboard">
                    <FaTachometerAlt className="nav-icon" /> Dashboard
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/dashboard/courses">
                    <FaBook className="nav-icon" /> My Courses
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/dashboard/my-transactions">
                    <FaDollarSign className="nav-icon" /> Transactions
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/dashboard/profile">
                    <FaUser className="nav-icon" /> Profile
                </NavLink>
            </NavItem>
        </Nav>
    );
};

export default DashboardNav;
