// src/pages/AdminTransactions.jsx
import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { getAllTransactions } from '../utils/api';
import AdminDashboardNav from '../components/AdminDashboardNav';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminTransactions.css';
import Header from './Header';
import Footer from './Footer';

const AdminTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            const response = await getAllTransactions();
            console.log(response);
            if (response.status === 'success') {
                setTransactions(response.transactions);
            }
        };
        fetchTransactions();
    }, []);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    // const handleDeleteTransaction = async () => {
    //     if (selectedTransaction) {
    //         const response = await deleteTransaction(selectedTransaction.id);
    //         if (response.status === 'success') {
    //             setTransactions(transactions.filter(transaction => transaction.id !== selectedTransaction.id));
    //             toast.success('Transaction deleted successfully');
    //         } else {
    //             toast.error('Failed to delete transaction');
    //         }
    //         toggleModal();
    //     }
    // };

    // const confirmDeleteTransaction = (transaction) => {
    //     setSelectedTransaction(transaction);
    //     toggleModal();
    // };

    return (
        <>
            <Header />
                <div className="admin-layout">
                    <AdminDashboardNav />
                    <div className="admin-content">
                        <Container fluid>
                            <Row className="mb-4">
                                <Col>
                                    <h1 className="text-center">All Transactions</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Transaction ID</th>
                                                <th>Course Name</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                {/* <th>Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.map((transaction, index) => (
                                                <tr key={transaction.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{transaction.id}</td>
                                                    <td>{transaction.course_name}</td>
                                                    <td>{transaction.created_at}</td>
                                                    <td>₦{transaction.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    {/* <td> */}
                                                        {/* <Button color="danger" onClick={() => confirmDeleteTransaction(transaction)}>
                                                            Delete
                                                        </Button> */}
                                                    {/* </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            {/* <Modal isOpen={isModalOpen} toggle={toggleModal}>
                                <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
                                <ModalBody>
                                    Are you sure you want to delete this transaction?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" onClick={handleDeleteTransaction}>Delete</Button>{' '}
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                </ModalFooter>
                            </Modal> */}
                        </Container>
                    </div>
                </div>
            <Footer />
        </>
        
    );
};

export default AdminTransactions;
