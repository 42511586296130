import React, { useState } from 'react';
import { login } from '../utils/api';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const LoginForm = ({ switchToRegister, switchToResetPassword }) => {
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [loginMessage, setLoginMessage] = useState('');

    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (validateEmail(loginData.email) && loginData.password) {
            try {
                const response = await login({ email: loginData.email, password: loginData.password });
                console.log(response);
                if (response.status === 'success') {
                    const user = response.user;
                    if (user.type === 'admin') {
                        window.location.href = '/admin';
                    } else {
                        window.location.href = '/dashboard';
                    }
                    // Redirect to dashboard or any other page
                    // window.location.href = '/dashboard';
                } else {
                    setLoginMessage(response.message);
                }
            } catch (err) {
                setLoginMessage('An error occurred. Please try again.');
            }

            // setLoginMessage('Login successful');
            setLoginData({ email: '', password: '' });
        } else {
            setLoginMessage('Please enter valid email and password');
        }
    };

    return (
        <div>
            <h2>Welcome back</h2>
            <p>Please enter your details.</p>
            <Form onSubmit={handleLoginSubmit}>
                <FormGroup>
                    <Label for="loginEmail">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="loginEmail"
                        placeholder="Enter your email"
                        value={loginData.email}
                        onChange={handleLoginChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="loginPassword">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="loginPassword"
                        placeholder="Enter your password"
                        value={loginData.password}
                        onChange={handleLoginChange}
                    />
                </FormGroup>
                <Button color="primary" type="submit" block>Continue</Button>
            </Form>
            {loginMessage && <Alert className="mt-3" color={loginMessage === 'Login successful' ? 'success' : 'danger'}>{loginMessage}</Alert>}
            <div className="text-center mt-3">
                <Button color="link" onClick={switchToResetPassword}>Forgot Password?</Button>
            </div>
            <div className="text-center mt-3">
                <Button color="link" onClick={switchToRegister}>Don't have an account? Sign up</Button>
            </div>
        </div>
    );
};

export default LoginForm;
