// src/pages/ProfileEdit.jsx
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { getProfile, editProfile } from "../utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProfileEdit.css";
import AdminDashboardNav from "../components/AdminDashboardNav";
import Footer from "./Footer";
import Header from "./Header";

const ProfileEdit = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    picture_link: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await getProfile();
      console.log(response);
      if (response.status === "success") {
        setFormData(response.user);
      }
      setLoading(false);
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await editProfile(formData);
    if (response.status === "success") {
      toast.success("Profile updated successfully");
    } else {
      toast.error("Failed to update profile");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <div className="admin-layout">
        <AdminDashboardNav />
        <div className="admin-content">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <h2>Edit Profile</h2>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={formData ? formData.email : ""}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={""}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="role">Role</Label>
                    <Input
                      type="text"
                      name="role"
                      id="role"
                      value={formData ? formData.role : ""}
                      onChange={handleChange}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="picture_link">Picture Link</Label>
                    <Input
                      type="text"
                      name="picture_link"
                      id="picture_link"
                      value={formData ? formData.picture_link : ""}
                      onChange={handleChange}
                      readOnly
                    />
                  </FormGroup>
                  <Button color="primary" type="submit" block>
                    Update Profile
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileEdit;
