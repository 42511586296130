// src/pages/Profile.jsx
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import { getProfile, editProfile, uploadImage } from '../utils/api';

import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';

import './Profile.css';



const Profile = () => {
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        pictureLink: '',
        password: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getProfile();
                if (response.status === 'success') {
                    setProfile(response.profile);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the profile. Please try again.');
            }
        };
        fetchProfile();
        $('.dropify').dropify();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        try {
            const data = await uploadImage(file);
            if (data.status === 'success') {
                setProfile((prevProfile) => ({
                    ...prevProfile,
                    pictureLink: data.file_link,
                }));
                setMessage('Image uploaded successfully.');
                setError('');
            } else {
                setError(data.message);
                setMessage('');
            }
        } catch (err) {
            setError('An error occurred while uploading the image. Please try again.');
            setMessage('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedProfile = { ...profile };
            if (!profile.password) {
                delete updatedProfile.password;
            }
            const response = await editProfile(updatedProfile);
            if (response.status === 'success') {
                setMessage('Profile updated successfully.');
                setError('');
            } else {
                setMessage('');
                setError(response.message);
            }
        } catch (err) {
            setMessage('');
            setError('An error occurred while updating the profile. Please try again.');
        }
    };

    return (
        <Container className="profile-form py-5">
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h2 className="text-center mb-4">Edit Profile</h2>
                    {message && <p className="message text-center">{message}</p>}
                    {error && <p className="error text-center">{error}</p>}
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                value={profile.name}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={profile.email}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="pictureLink">Profile Picture URL</Label>
                            <Input
                                type="text"
                                name="pictureLink"
                                id="pictureLink"
                                value={profile.pictureLink}
                                onChange={handleChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="profilePicture">Upload Profile Picture</Label>
                            <Input
                                type="file"
                                className="dropify"
                                data-default-file={profile.pictureLink}
                                onChange={handleImageUpload}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={profile.password}
                                onChange={handleChange}
                            />
                            <small className="form-text text-muted">
                                Leave blank to keep current password
                            </small>
                        </FormGroup>
                        <Button color="primary" type="submit" block>
                            Save Changes
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
