// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserData } from '../utils/api';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const user = getUserData();
    const isAuthenticated = !!user;
    const isAdmin = isAuthenticated && user.type === 'admin';

    if (isAuthenticated && rest.path === '/account') {
        return isAdmin ? <Navigate to="/admin" /> : <Navigate to="/dashboard" />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;