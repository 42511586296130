// src/components/Header.js
import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/" className="mr-auto">
                Schedular
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mx-auto" navbar>
                    <NavItem>
                        <NavLink href="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/courses">Courses</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/instructors">Instructors</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/contact">Contact</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/account">My Account</NavLink>
                    </NavItem>
                </Nav>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href="https://twitter.com">
                            <i className="fab fa-twitter"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://instagram.com">
                            <i className="fab fa-instagram"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://facebook.com">
                            <i className="fab fa-facebook"></i>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Header;
