// src/pages/InstructorProfile.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getInstructor, API_BASE } from '../utils/api';
import './InstructorProfile.css';

const InstructorProfile = () => {
    const { id } = useParams();
    const [instructor, setInstructor] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchInstructor = async () => {
            try {
                const response = await getInstructor(id);
                if (response.status === 'success') {
                    setInstructor(response.instructor);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the instructor. Please try again.');
            }
        };
        fetchInstructor();
    }, [id]);

    if (error) {
        return <p className="error text-center">{error}</p>;
    }

    if (!instructor) {
        return <p className="text-center">Loading...</p>;
    }

    return (
        <>
            <Header />
            <Container className="instructor-profile py-5">
                <Row>
                    <Col md="4">
                        <Card>
                            <CardBody>
                                <img src={API_BASE + instructor.image} alt={instructor.name} className="img-fluid mb-3" />
                                <CardTitle tag="h5">{instructor.name}</CardTitle>
                                <CardText><strong>Expertise:</strong> {instructor.expertise}</CardText>
                                <CardText>{instructor.bio}</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default InstructorProfile;
