import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <footer className="footer py-5 bg-dark text-white">
            <Container>
                <Row>
                    <Col md="3">
                        <h5>Schedular</h5>
                        <p>Donec nec ante nisi. Vestibulum tincidunt lectus sed magna fringilla sagittis.</p>
                        <p>© 2022 Your Company. All rights reserved.</p>
                    </Col>
                    <Col md="3">
                        <h5>Tags</h5>
                        <p>Health • Lifestyle • Social • Entertainment • News • Books • Design • Gadgets</p>
                    </Col>
                    <Col md="3">
                        <h5>Social</h5>
                        <p>Twitter • Facebook • Instagram • Youtube</p>
                    </Col>
                    <Col md="3">
                        <h5>About</h5>
                        <p>Shop • Authors • Sitemap • About us • Contact</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;