import React, { useEffect, useState} from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import './UpcomingCourses.css';
import { getUpcomingCourses, API_BASE } from '../utils/api';
import course1 from '../assets/2150352184.jpg'
import { Link } from 'react-router-dom';

const UpcomingCourses = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchUpcomingCourses = async () => {
            try {
                const response = await getUpcomingCourses();
                console.log(response)
                if (response.status === 'success') {
                    setCourses(response.courses);
                } else {
                    console.error('Failed to fetch upcoming courses:', response.message);
                }
            } catch (error) {
                console.error('Error fetching upcoming courses:', error);
            }
        };

        fetchUpcomingCourses();
    }, []);

    return (
        <section className="upcoming-courses py-5">
            <Container>
                <h2 className="text-center mb-4">Upcoming Courses</h2>
                <Row>
                    {courses.length > 0 ? courses.map(course => (
                        <Col md="4" key={course.id} className='mb-4'>
                            <Card>
                                <Link to={`/course/${course.id}`}>
                                    <CardImg className='course-image' top width="100%" src={API_BASE + course.image_link} alt="Card image cap" />
                                    <CardBody className="text-center">
                                        <CardTitle tag="h5">{course.title}</CardTitle>
                                        <CardText tag="h4">{course.instructor_name} • {new Date(course.start_date).toLocaleDateString()}</CardText>
                                        <CardText>{course.description}</CardText>
                                    </CardBody>
                                </Link>
                            </Card>
                        </Col>
                    )) : (
                        <Col>
                            <p className="text-center">No upcoming courses found.</p>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    );
};

export default UpcomingCourses;
