import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const RegisterForm = ({ switchToLogin }) => {
    const [registerData, setRegisterData] = useState({ name: '', email: '', password: '', confirmPassword: '' });
    const [registerMessage, setRegisterMessage] = useState('');

    const handleRegisterChange = (e) => {
        const { name, value } = e.target;
        setRegisterData({ ...registerData, [name]: value });
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (
            registerData.name &&
            validateEmail(registerData.email) &&
            registerData.password &&
            registerData.password === registerData.confirmPassword
        ) {
            setRegisterMessage('Registration successful');
            setRegisterData({ name: '', email: '', password: '', confirmPassword: '' });
        } else {
            setRegisterMessage('Please fill all fields correctly');
        }
    };

    return (
        <div>
            <h2>Create an account</h2>
            <Form onSubmit={handleRegisterSubmit}>
                <FormGroup>
                    <Label for="registerName">Name</Label>
                    <Input
                        type="text"
                        name="name"
                        id="registerName"
                        placeholder="Enter your name"
                        value={registerData.name}
                        onChange={handleRegisterChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="registerEmail">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="registerEmail"
                        placeholder="Enter your email"
                        value={registerData.email}
                        onChange={handleRegisterChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="registerPassword">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="registerPassword"
                        placeholder="Enter your password"
                        value={registerData.password}
                        onChange={handleRegisterChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="registerConfirmPassword">Confirm Password</Label>
                    <Input
                        type="password"
                        name="confirmPassword"
                        id="registerConfirmPassword"
                        placeholder="Confirm your password"
                        value={registerData.confirmPassword}
                        onChange={handleRegisterChange}
                    />
                </FormGroup>
                <Button color="primary" type="submit" block>Sign Up</Button>
            </Form>
            {registerMessage && <Alert className="mt-3" color={registerMessage === 'Registration successful' ? 'success' : 'danger'}>{registerMessage}</Alert>}
            <div className="text-center mt-3">
                <Button color="link" onClick={switchToLogin}>Already have an account? Login</Button>
            </div>
        </div>
    );
};

export default RegisterForm;
