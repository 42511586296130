// src/utils/api.js
import axios from 'axios';

const API_BASE_URL = 'http://127.0.0.1:5454/index.php';
// const API_BASE_URL = 'https://api.myafriart.com/index.php';

const API_BASE = 'http://127.0.0.1:5454/';
// const API_BASE = 'https://api.myafriart.com/';

const UPLOAD_URL = 'http://127.0.0.1:5454/upload.php';
// const UPLOAD_URL = 'https://api.myafriart.com/upload.php';

// Function to get the auth token from localStorage
const getToken = () => localStorage.getItem('token');

// Function to set the auth token in localStorage
const setToken = (token) => localStorage.setItem('token', token);

// Function to save user data in localStorage
const setUserData = (user) => localStorage.setItem('user', JSON.stringify(user));

// Function to get user data from localStorage
const getUserData = () => JSON.parse(localStorage.getItem('user'));

// Function to remove the auth token from localStorage
const removeToken = () => localStorage.removeItem('token');

// Axios instance with base URL
const api = axios.create({
    baseURL: API_BASE_URL,
});

// Request interceptor to add the token to headers
api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// API helper functions
const register = async (userData) => {
    const response = await api.post('?action=register', userData);
    return response.data;
};

const login = async (credentials) => {
    const response = await api.post('?action=login', credentials);
    console.log(response)
    if (response.data.status === 'success') {
        setToken(response.data.token);
        setUserData(response.data.user);
    }
    return response.data;
};

const logout = () => {
    removeToken();
};

const getProfile = async () => {
    const response = await api.get('?action=get_profile');
    return response.data;
};

const editProfile = async (profileData) => {
    const response = await api.post('?action=edit_profile', profileData);
    return response.data;
};

const getCourses = async () => {
    const response = await api.get('?action=get_courses');
    return response.data;
};

const getCourse = async (id) => {
    const response = await api.get(`?action=get_course&id=${id}`);
    return response.data;
};

const getAllCourses = async () => {
    const response = await api.get(`?action=get_all_courses`);
    return response.data;
};

const createCourse = async (courseData) => {
    const response = await api.post('?action=create_course', courseData);
    return response.data;
};

const updateCourse = async (courseData) => {
    const response = await api.post('?action=update_course', courseData);
    return response.data;
};

const deleteCourse = async (id) => {
    const response = await api.post('?action=delete_course', {
        id
    });
    return response.data;
};

const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(UPLOAD_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

const getMyCourses = async () => {
    const response = await api.get('?action=get_my_courses');
    console.log(response)
    return response.data;
};

const getMyTransactions = async () => {
    const response = await api.get('?action=get_my_transactions');
    return response.data;
};

const createInstructor = async (instructorData) => {
    const response = await api.post('?action=create_instructor', instructorData);
    return response.data;
};

const editInstructor = async (instructorData) => {
    const response = await api.post('?action=edit_instructor', instructorData);
    return response.data;
};

const deleteInstructor = async (id) => {
    const response = await api.post('?action=delete_instructor', {
        id
    });
    return response.data;
};

const getInstructors = async () => {
    const response = await api.get('?action=get_instructors');
    return response.data;
};

const getInstructor = async (id) => {
    const response = await api.get(`?action=get_instructor&id=${id}`);
    return response.data;
};

const getTotalUsers = async () => {
    const response = await api.get('?action=get_total_users');
    return response.data;
};

const getTotalCourses = async () => {
    const response = await api.get('?action=get_total_courses');
    return response.data;
};

const registerCourse = async (courseId, amount) => {
    const token = getToken();
    const response = await api.post(
        '?action=register_course', {
            course_id: courseId,
            amount: amount
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

const searchCourses = async (searchParams) => {
    const response = await api.post('?action=search_courses', searchParams);
    return response.data;
};

const getUpcomingCourses = async (limit = 3) => {
    const response = await api.get(`?action=get_upcoming_courses&limit=${limit}`);
    return response.data;
};

const createUser = async (userData) => {
    const response = await api.post('?action=create_user', userData);
    return response.data;
};

const getAllUsers = async () => {
    const response = await api.get('?action=get_all_users');
    return response.data;
};

const getAllTransactions = async () => {
    const response = await api.get('?action=get_transactions');
    return response.data;
};

const getSingleUser = async (id) => {
    const response = await api.get(`?action=get_single_user&id=${id}`);
    return response.data;
};

const editUser = async (userData) => {
    const response = await api.post('?action=edit_user', userData);
    return response.data;
};

const deleteUser = async (id) => {
    const response = await api.post('?action=delete_user', { id });
    return response.data;
};

export {
    register,
    login,
    logout,
    getProfile,
    editProfile,
    getCourses,
    getCourse,
    createCourse,
    updateCourse,
    deleteCourse,
    getMyCourses,
    getMyTransactions,
    createInstructor,
    editInstructor,
    deleteInstructor,
    getInstructors,
    getInstructor,
    uploadImage,
    getTotalUsers,
    getTotalCourses,
    getUserData,
    registerCourse,
    getAllCourses,
    searchCourses,
    getUpcomingCourses,
    createUser,
    getAllUsers,
    getSingleUser,
    editUser,
    deleteUser,
    getAllTransactions,
    API_BASE
};
